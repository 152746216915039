import React from "react";

import abacavideo from "../../../images/videos/abacavideo.mp4";

import CloseIconMdi from "mdi-react/CloseIcon";

import styled, { css } from "styled-components";

export default function Modal(props) {
    if (!props.isModalOpen) {
        return null;
    }

    return (
        <div className="modalWrapper">
            <div>
                <CloseIcon onClick={props.openModal} className="closeIcon" />
            </div>
            <div className="modalContainer">
                <div className="modalContent">
                    <video className="modalVideo" controls>
                        <source src={abacavideo} type="video/mp4" />
                    </video>
                </div>
            </div>
            <div className="modalDimmer"></div>
        </div>
    );
}

const CloseIcon = styled(CloseIconMdi)`
    cursor: pointer;
    color: white;
    z-index: 100;
    margin: 24px 22px 0 0;
    float: right;

    @media (max-width: 1199px) {
        width: 24px;
        height: 24px;
        margin: 6px 12px 0 0;
    }

    @media (max-width: 575px) {
        width: 24px;
        height: 24px;
        margin: 12px 12px 0 0;
    }
`;
